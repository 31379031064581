.MatchDetailHeader {

  --avatar-shadow-intensity: 1;
  background-color: var(--color-background-default);
  display: flex;
  flex-direction: column;

  .MatchCardHeader {
    column-gap: 40px;
    grid-template-columns: 1fr auto 1fr;
    .CurrentSetScore {
      font-size: 3em;
    }
  }

  .MatchCardBody {
    .Scores > .TeamScore {
      align-self: flex-start;
      font-size: 2em;
      font-weight: bold;
      &.finished {
        font-size: 2.5em;
      }
    }
  }

  .backlink {
    font-size: 18px;
    cursor: pointer;
    color: var(--color-text);
    margin-top: 40px;
  }

  .inner {
    margin: 0 auto;
  }

  .inner>* {
    text-align: center;
  }
  height: auto;

}
