.View-selection button {
  opacity: 0.7;
  line-height: 1.5em;
  cursor: pointer;
  color: var(--color-text);
  font-size: 16px;
  @media screen and (min-width: 375px) {
    font-size: 20px;
  }
}

.View-selection button.active {
  opacity: 1;
}

.View-selection {
  svg {
    color: var(--color-text);
    size: 50px;
  }
  .Cards-view {
    border-right: 1px solid #C8C8C8;
    padding-right: 20px;
  }
  .List-view {
    padding-left: 20px;
  }
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
