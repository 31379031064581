.CurrentSetScore {
  font-weight: bolder;
  color: var(--score-color-text-primary, var(--color-text));
  margin: 20px 0;
}

.CurrentSetScore .inactive {
  color: var(--score-color-text-inactive, lightgrey);
}


@media screen and (max-width: 800px) {
  .CurrentSetScore {
    margin: 10px 0;
  }
}
