.MatchStats {

  svg {
    fill: white;
  }

  @media screen and (min-width: 812px) {
    margin: 20px;
  }

  margin-top: 15px;

  .icon {
    svg {
      width: 35px;
      fill: white;
    }
  }

  .stats-container {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    margin: 15px 0px;

    svg {
      margin-left: 0.2vw;
    }
  }

  table {
    width: 100%;
    text-align: center;
  }

  td,
  th {
    padding: 15px 0px;
    background: #f9fbfb;

    @media screen and (max-width: 568px) {
      font-size: 3.5vw;
    }

    @media screen and (min-width: 812px) {
      font-size: 1vw;
    }

    font-size: 1.2vw;
  }

  h1 {
    min-height: 50vh;
  }

  h2 {
    text-align: center;
    margin: 15px;
  }

}