.SetScoreHistory {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.SetScoreHistory .SetScoreHistoryEntry {
  margin-bottom: 5px;
}
