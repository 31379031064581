.PlayerCard {
  font-size: 10px;
  text-align: center;
}

.PlayerCard .Avatar {
  margin-bottom: 10px;
}

.PlayerCard-jersey {
  font-weight: bold;
  margin-right: .35em;
  display: inline-block;
}

.PlayerCard-position {
  font-weight: bold;
}
