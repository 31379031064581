.RankingCard {
  display: table-row;

  .Ranking-position {
      font-weight: bold;
  }

  .Ranking-score {
      font-weight: bold
  }

  > * {
      display: table-cell;
      vertical-align: middle;
      font-size: 18px;
      padding: 5px;
  }

}

