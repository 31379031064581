.Rankings .tile .Avatar {
  grid-area: avatar;
  margin-right: 10px;
}

.Rankings>.table {
  width: 100%;
  display: table;
}

.Rankings>.table .header {
  display: table-row;
}

.Rankings>.table .header > * {
  display: table-cell;
  color: var(--color-text-table-header);
}
