.SetScoreHistoryEntry {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  font-weight: bold;
  text-align: center;
  box-sizing: content-box;
  width: 120px;
  justify-content: center;

  .team-score {
    color: var(--score-color-text-primary, var(--color-text));
  }

  .set-number {
    letter-spacing: 4px;
    color: var(--score-color-text-secondary, var(--color-text-secondary));
  }
}
