.FilterNav a.checkboxLink {
  cursor: pointer;
  word-break: break-word;
}

.FilterNav {
  div.gender-nav {
    display: flex;
    border-bottom: 1px solid var(--color-text-secondary);
    margin: 5px 0;
  }

}

.group {
  position: relative;
  margin-bottom: 15px;

  label {
    color: var(--color-text-secondary);
    font-size: 16px;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
    &:before {
      content: '';
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: var(--color-text-secondary);
      transition: 300ms ease all;
      left: 0%;
    }
  }

  input {
    &:focus {
      outline: none;
    }
    &:focus ~ label,
    &:valid ~ label {
      top: -14px;
      font-size: 16px;
    }
    &:focus ~ .bar:before {
      width: 100%;
    }
  }

  input {
    width: 100%;
    background: none;
    color: var(--color-text-secondary);
    font-size: 18px;
    padding: 10px 10px 5px 5px;
    display: block;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--color-text-secondary);
  }
}


.FilterNav a {
  padding: 10px 8px 10px 0;
  font-size: 1.2rem;

  &.favorites {
    border-top: 1px solid var(--color-text-secondary);
    padding: 15px 0;
  }



  &.reset {
    border-top: 1px solid var(--color-text-secondary);
    font-weight: bold;
    padding: 15px 0;

    .fa-xmark {
      margin-right: 10px;
    }
  }

}

.FilterNav a.checkboxLink:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  font-size: 0;
  flex: 0 0 18px;
}

.FilterNav a.checkboxLink.selected:before {
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 50% 50%;
}

.FilterNav {
  .container {
    display: block;
    position: relative;
    padding-left: 28px;
    padding-right: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: none;
    border: 1px solid var(--color-text-secondary);
  }

  .container input:checked~.checkmark {
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 50% 50%;
    background-image: linear-gradient(var(--color-text-secondary), var(--color-text-secondary));
  }

}