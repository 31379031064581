/* The side navigation menu */
$sidenav-button-padding-modile: 10px;

.SideNav {
  min-height: 100vh;
  width: 0;
  position: fixed;
  z-index: 1000;
  overflow: visible;
  transition: width 0.2s ease-out;

  nav a {
    text-decoration: none;
    color: inherit;
    display: flex;
  }

  h1 {
    font-size: 1.8rem;
  }

  .SideNav-button {
    position: absolute;
    cursor: pointer;
    top: 25px;
    font-size: 40px;

    &.SideNav-closeButton {
      left: -25px;
      transform: translateX(-100%);
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
      pointer-events: none;
    }

    &.SideNav-showButton {
      left: 25px;
    }

    @media screen and (max-width: 800px) {
      top: $sidenav-button-padding-modile;

      &.SideNav-showButton {
        left: $sidenav-button-padding-modile;
      }

      &.SideNav-closeButton {
        left: -$sidenav-button-padding-modile;
      }

      font-size: 26px;
    }
  }

  &.active,
  .SideNav-inner {
    box-sizing: border-box;
    width: 300px;

    h1 {
      text-align: start;
      font-weight: bold;
    }

    @media screen and (max-width: 800px){
      h1 {
        margin: 10px 0;
      }
    }
    @media screen and (max-width: 360px) {
      width: 260px;
    }
  }

  .SideNav-inner {
    position: absolute;
    top: 0;
    padding: 25px;
    padding-bottom: 100px;
    z-index: 100;
    height: 100%;
    overflow-y: auto;
  }

  &.SideNav-right {
    top: 0;
    right: 0;

    .SideNav-inner {
      left: 0;
    }

    .SideNav-button {
      &.SideNav-showButton {
        left: auto;
        right: 25px;
        @media screen and (max-width: 800px) {
          right: $sidenav-button-padding-modile;
        }
      }
    }
  }

  &.SideNav-left {
    top: 0;
    left: 0;

    .SideNav-inner {
      right: 0;
    }

    .SideNav-closeButton {
      left: auto;
      right: -25px;
      transform: translateX(100%);
      @media screen and (max-width: 800px) {
        right: -$sidenav-button-padding-modile;
      }
    }
  }

  &.active .SideNav-closeButton {
    opacity: 1;
    pointer-events: all;
  }

}

.SideNav-overlay {
  z-index: 10;
}
