.Avatar {
  background: radial-gradient(ellipse closest-side at center, rgba(0, 0, 0, var(--avatar-shadow-intensity, 0.25)) 0%, rgba(0, 0, 0, var(--avatar-shadow-intensity, 0.25)) 40%, rgba(0, 0, 0, 0) 100%);
  background-position: center calc(var(--avatar-size, 50px) / 5 * 4);
  background-size: var(--avatar-size, 50px) calc(var(--avatar-size, 50px) / 3);
  background-repeat: no-repeat;
  padding-bottom: 10px;
}
.Avatar-content {
  margin: 0 auto;
  overflow: hidden;
  font-size: calc(var(--avatar-size, 50px) / 2);
  width: var(--avatar-size, 50px);
  height: var(--avatar-size, 50px);
  border-radius: 50%;
  background-color: var(--avatar-color-background);
  background-repeat: no-repeat;
  background-size: 100%;
  color: var(--avatar-color-text);
  display: flex;
  align-items: center;
  justify-content: center;
}
