.LiveReferees {
}
.LiveReferees .tile {
  display: grid;
  font-size: 10px;
  grid-template-columns: min-content auto;
  grid-template-areas:
    "avatar name"
    "avatar type";
  align-items: center;
}
.LiveReferees .tile .Avatar {
  grid-area: avatar;
  margin-right: 10px;
}
.LiveReferees-name {
  grid-area: name;
}
.LiveReferees-type {
  grid-area: type;
  font-weight: bold;
  align-self: start;
}
