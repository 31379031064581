ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* Avoid the IE 10-11 `min-height` bug. */
  padding: 0;
}

.MatchesOverview.header-fixed .OverviewHeader {
  position: fixed;
  top: 0;
  height: 80px;
  box-shadow: var(--box-shadow);
  .App-title { 
    display: none;
  }
}

.MatchesOverview.header-fixed {
  margin-top: 260px;
}

.App-logo {
  height: 230px;
}

.App-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 100%;
  right: 100%;
  z-index: 500;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
}

.App-overlay.active {
  bottom: 0;
  right: 0;
  opacity: 0.7;
  background-color: #000;
}


.App-title {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 90px;
  white-space: nowrap;
}

@media only screen and (max-width: 800px) {
  .App-title {
    font-size: 35px;
  }

}

.Hero-text {
  width: 640px;
  height: 74px;
  font-family: SizmoPro;
  font-size: 70px;
  font-weight: bold;
  font-style: oblique;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.MatchCards {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100vw;
}

.MatchCards.center {
  justify-content: center;
}


/* this sucks: */
@media screen and (min-width: 400px) {
  .MatchCards {
    width: 400px;
  }
}

@media screen and (min-width: 800px) {
  .MatchCards {
    width: 800px;
  }
}

@media screen and (min-width: 1200px) {
  .MatchCards {
    width: 1200px;
  }
}

@media screen and (min-width: 1600px) {
  .MatchCards {
    width: 1600px;
  }
}

@media screen and (min-width: 2000px) {
  .MatchCards {
    width: 2000px;
  }
}


.MatchCards>* {
  flex: 0 auto;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}
