.Match-list-item {

  background-color: white;
  position: relative;
  display: flex;
  flex-wrap: no-wrap;
  align-items: stretch;
  font-size: 20px;

  >* {
    padding: 10px;
    border-bottom: 1px solid #eee;
    width: 27%;
    flex-basis: 27.3%;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .Match-list-match-series {
    font-size: 16px;
    border-bottom: 0;
    flex-grow: 0;
    flex-basis: 100%;
    padding-bottom: 0;
    padding-top: 20px;
    &.rescheduled {
      color: gray;
    }
  }

  flex-wrap: wrap;
  font-size: 16px;

  >* {
    width: 33%;
    flex-basis: 33%;
  }

  .Match-date {
    font-size: 1.4em;
  }

  .Match-score {
    display: flex;
    align-items: baseline;
    justify-content: center;

    .TeamScore {
      font-size: 2em;
    }

    .CurrentSetScore {
      font-size: 3em;
      margin: 0 15px;
    }

    @media screen and (max-width: 800px) {
      font-size: 14px;

      .Match-score .CurrentSetScore {
        margin: 0 5px;
      }
    }
  }

  .Match-list-item-match-info {
    text-align: center;
    flex-basis: 10%;
  }

  &.finished {
    .Match-score {
      .TeamScore {
        font-size: 2.5em;
      }

      .CurrentSetScore {
        font-size: 1.6em;
        margin: 0 1.5vw;
      }
    }
    background-color: #f9fbfb;
  }



  .Match-list-item-team {
    .TeamLogo {
      flex-direction: row;
      display: flex;
      align-items: center;
      align-content: space-between;
      width: 100%;

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }

    }

    @media screen and (min-width: 801px) {
      .TeamLogo-image {
        flex: 0 0 80px;
      }

      .TeamLogo-name {
        flex: 1 0 calc(100% - 80px);
        font-size: 20px;
        padding: 1em;
        text-align: left;
      }

      &.Match-list-item-team2 {
        .TeamLogo {
          >* {
            order: 1;
          }

          .TeamLogo-name {
            text-align: right;
          }
          .TeamLogo-image {
            order: 2;
          }
        }
      }
    }
  }



  .Match-info {
    flex: 1 0 33%;
  }

  .Match-detail-link {
    color: inherit;
  }



}
