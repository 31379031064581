.MatchCardHeader {
  margin-top: 15px;
  min-height: 150px;
  display: grid;
  align-items: center;
  text-align: center;
  grid-template-columns: 120px auto 120px;
  max-width: 100%;
  margin-bottom: 15px;
}

.MatchCard{
  .Match-info.rescheduled {
    color: gray;
    .Match-time {
      color: grey;
    }
  }
}

.rescheduled-info {
  margin-top: 10px;
  font-size: 1.4em;
}

.Team-description {
  white-space: pre-wrap;
}

.Match-time {
  font-size: 1.8em;
  font-weight: bolder;
  color: var(--score-color-text-primary, var(--color-text));
  margin: auto 0;
}
