.MatchDetailBody {
  &.mobile {
    .LiveTicker {
      width: 100vw;
    }
  }
  &.desktop {

    .right-column >* {
      margin-bottom: 15px;
    }

    display: flex;
    justify-content: center;

    >* {
      flex-basis: 20%;
    }

    .LiveLineups {
      flex-basis: auto;
    }

    .LiveTicker {
      flex-basis: 40%;
    }

    @media screen and (max-width: 800px) {
      >* {
        flex-basis: 100%;
      }

      .LiveLineups {
        flex-basis: 100%;
      }

      .LiveTicker {
        flex-basis: 100%;
      }
    }

  }

}

.MatchDetailBody>* {
  margin: 20px 20px;
}


@media screen and (max-width: 800px) {
  .MatchDetailBody>* {
    margin: 20px 10px;
  }

  .MatchDetailBody>.LiveLineups {

    .bench {
      max-width: inherit;
    }

    .inner .field {
      width: auto;
    }
  }

}

h1 {
  text-align: center;
  margin: 30px 0;
}