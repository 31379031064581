.App-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 85px;
  flex-shrink: 0;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */

  a {
    color: inherit;
  }
  img {
    height: 55%;
  }
  flex-shrink: 0;
  .version-tag {
    font-size: 8px;
    color: whitesmoke;
    margin-left: 40px;
  }
}
