.MatchCardBody {
  flex: 0 1 100%;


  .info-text {
    font-size: 20px;
    text-align: center;
    margin: 15px;
  }
}
