
:root {
  --color-text: #112;
  --color-text-secondary: #666;
  --color-text-table-header: var(--color-text);
  --color-red: #f46a6a;
  --color-green: #7eeab0;
  --color-negative: var(--color-red);
  --color-positive: var(--color-green);
  --box-shadow: 0 1px 20px -10px rgba(0, 0, 0, 0.35);
  --color-background-default: #aaa;
  --color-background-darker: #999;
  --avatar-color-background: var(--color-background-default);
  --avatar-color-text: white;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--color-text);
}

*:focus {
  outline-color: var(--color-text);
}

svg {
  fill: var(--color-text);
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, ul, li {
  text-decoration: none;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.rotate-90 {
  transform: rotate(90deg);
}

button, input[type=submit] {
  border: none;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}

.ticker-icon {
  font-family: "ticker-icons";
  font-style: normal;
  font-weight: normal;
}
