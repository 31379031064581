.FavouritesButton {
  font-size: 14px;
  .fa-heart{
    margin-right: 8px;
    font-size: 16px;
  }
}

.FavouritesButton.active {
  .fa-heart  {
    color: var(--color-red);
  }
}
