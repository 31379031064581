.EventEntry {
  display: grid;
  grid-template-columns: 60px auto 60px;
  column-gap: 16px;
  padding: 12px 25px 12px 25px;
  align-items: center;
}

.EventEntry .meta {
  font-size: 20px;
}
.EventEntry .meta .icon img{
  width: 100%;
}

.EventEntry .meta .score, .EventEntry .meta .time {
  display: flex;
  justify-content: space-between;
}

.EventEntry .content p, .EventEntry .content h2 {
  margin: 0;
  padding: 0;
}

.EventEntry .content p {
  margin-top: 5px;
}
