.OverviewHeader {
  display: flex;
  height: 260px;
  min-height: 80px;
  width: 100%;
  position: relative;
  padding: 15px 15px 10px 15px;
  background-position: center top;
  justify-content: center;
  align-items: flex-end;
  z-index: 100;
  box-shadow: none;
  transition: box-shadow 300ms;
}

@media only screen and (max-width: 800px) {
  .OverviewHeader {
    height: 130px;
    padding: 0 0 5px 0;
  }

  .MatchesOverview.header-fixed {
    margin-top: 130px;
  }
}

.MatchesOverview {
  .App-title {
    opacity: 1;
    transition: opacity 300ms;
  }
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 85px);

}

.MatchDays {
  h1 {
    text-align: center;
  }
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
