.MatchInfoHeader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .set-score {
    display: flex;
    align-items: center;
      font-size: 55px;

    .TeamScore {
        margin: 0px 35px;
        font-size: 35px;

    }
  }

  @media screen and (max-width: 800px) {
    .set-score {
      font-size: 35px;

      .TeamScore {
      margin: 0px 10px;
      font-size: 25px;
      }
    }
  }

  .TeamLogo-image {

    background: radial-gradient(ellipse closest-side at center, rgba(0, 0, 0, 0.13) 0%, rgba(0, 0, 0, 0.13) 50%, rgba(0, 0, 0, 0) 100%);
    background-position: center 72px;
    background-size: 65px 12px;
    background-repeat: no-repeat;
    box-sizing: content-box;
    height: 60px;
    width: 60px;

    >* {
      height: 60px;
      width: 60px;
    }

    .LogoImage {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .AvatarLogo {
      overflow: hidden;
      text-align: center;
      border-radius: 50%;
      background-color: #aaa;
      color: white;
      font-weight: bold;
      font-size: 80px;
    }

    @media screen and (max-width: 800px) {
      height: 50px;
      width: 50px;


      >* {
        height: 50px;
        width: 50px;
      }

      .AvatarLogo {
        font-size: 50px;
      }
    }
  }
}