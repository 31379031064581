.MatchCard {

  .StatsButton {
    font-size: 14px;
    .fa-chart-bar{
      margin: 0 8px;
      font-size: 16px;
    }
  }


  padding-bottom: 40px 0;
  width: 100vw;
  max-width: 400px;
  height: 400px;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;

  .Scores > * {
    text-align: center;
  }
  .Scores > .TeamScore {
    align-self: flex-start;
    font-size: 2em;
    &.finished {
      font-size: 3em;
    }
  }

  .CurrentSetScore {
    font-size: 3em;
    &.finished {
      font-size: 2em;
    }
  }

  &.finished {
    background-color: #f9fbfb;
  }

  a{
    color: inherit;
  }

  .TeamLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MatchCardFooter {
    position: absolute;
    left: 15px;
    bottom: 35px;
  }

}

.MatchCardFooter {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0px 15px;
}

.wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
}


.Match-info {

  align-self: flex-start;
  margin-top: 10px;
  .Match-series-name {
    font-weight: bold;
    word-break: break-word;
    margin-bottom: 4px;
  }

  .Match-date {
    >* {
      margin-bottom: 4px;
    }
  }

  flex: 1;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
