.LiveLineup {
  @media screen and (min-width: 569px){
    max-width: 350px;
  }

  $field-margin: 10px;

  display: flex;
  flex-direction: column;

  .bench {
    display: flex;
    flex-wrap: wrap;
    --avatar-size: 30px;
  }

  .trainer {
    margin: 5px 0;
    font-size: $field-margin;
  }

  .bench>* {
    margin: $field-margin 5px;
  }

  >* {
    order: 1;
  }

  &.right .trainer {
    order: 10;
    text-align: right;
  }

  &.left .bench {
    justify-content: flex-end;
  }

  &.right .bench {
    order: 20;
  }

  .inner {
    display: flex;
    flex-direction: column;
    background-color: var(--lineup-field-color-background, #dde1e7);
    --avatar-color-background: white;
    --avatar-color-text: var(--color-background-default);

    > {
      h1 {
        background-color: var(--lineup-field-color-sides-background, #b2c5d3);
        margin: 0;
        padding: 4px $field-margin;
        font-size: 11px;
        font-weight: bold;
      }

      * {
        order: 1;
      }
    }
  }

  &.right h1 {
    order: 99;
  }

  .field {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    border: 1px solid white;
    margin: 0 $field-margin;
    padding: 0 20px;
    height: 250px;

    img {
      position: absolute;
      bottom: 30px;
    }
  }

  &.left .field {
    align-items: start;
    margin-top: $field-margin;
    grid-template-areas:
    "pos-1 pos-6 pos-5"
    "pos-2 pos-3 pos-4";
  }

  &.right .field {
    margin-bottom: $field-margin;
    align-items: end;
    border-top: none;
    grid-template-areas:
      "pos-4 pos-3 pos-2"
      "pos-5 pos-6 pos-1";
  }

  .field>* {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &.right .field>* {
    padding-bottom: 5px;
  }

  &.left .field>* {
    padding-top: 5px;
  }

  &.right {

    .pos-3,
    .pos-6 {
      top: -15px;
    }
  }

  &.left {

    .pos-3,
    .pos-6 {
      top: 15px;
    }
  }

  @for $i from 1 through 6 {
    .pos-#{$i} {
      grid-area: pos-#{$i};
    }
  }
}
