.left-column > div {
  margin-bottom: 15px;
}

.MatchDetail .MatchDetailHeader.header-fixed {
  width: 100%;
  position: sticky;
  top: 0;
  box-shadow: var(--box-shadow);
}

.MatchDetailHeader {
  transition: box-shadow 300ms;
  z-index: 100;
  font-size: 20px;
  @media screen and (max-width: 800px){
    font-size: 16px;
    >.inner{

      >.MatchCardHeader{
        >.TeamLogo{
          >.TeamLogo-name{
            font-size: 14px;
          }
        }
        >.Match-info{
          line-height: 24px;
          >.Match-date{
            >.CurrentSetScore{
              font-size: 2.3em;
            }
          }
        }
      }
    }
  }
}
.MatchDetailBody {
  position: relative;
  z-index: 10;
}
