.tile {
  background-color: white;
  border-left-width: 1px;
  border-left-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: 1px;
  margin-bottom: 1px; 
  border-image: linear-gradient(lighten(lightgrey, 15%), lighten(lightgrey, 5%), lighten(lightgrey, 13%)) 1;
  position: relative;
  padding: 10px;
}
.tile:empty {
  display: none;
}

.tile-shadow:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
}
