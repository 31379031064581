.TeamLogo {
  text-align: center;
  align-self: normal;

  .LogoImage.rescheduled {
    filter: grayscale(1);
  }

  &.leftTeam {
    padding-left: 15px;
  }

  &.rightTeam {
    padding-right: 15px;
  }

  margin-right: auto;
  margin-left: auto;

  .TeamLogo-name {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    @media screen and (max-width: 450px) {
      max-width: 100%;
    }
    &.rescheduled {
      color: gray!important;
    }
  }

  .TeamLogo-image {

    background: radial-gradient(ellipse closest-side at center, rgba(0, 0, 0, 0.13) 0%, rgba(0, 0, 0, 0.13) 50%, rgba(0, 0, 0, 0) 100%);
    background-position: center 72px;
    background-size: 65px 12px;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    box-sizing: content-box;
    height: 85px;
    width: 80px;
    margin-right: auto;
    margin-left: auto;

    >* {
      height: 80px;
      width: 80px;
    }

    .LogoImage {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .AvatarLogo {
      overflow: hidden;
      text-align: center;
      border-radius: 50%;
      background-color: #aaa;
      color: white;
      font-weight: bold;
      font-size: 80px;
      margin-bottom: 10px;
    }




    @media screen and (max-width: 800px) {
      height: 50px;
      width: 50px;


      >* {
        height: 50px;
        width: 50px;
      }

      .AvatarLogo {
        font-size: 50px;
      }
    }
  }
}
