.Match-filter {
  @media screen and (max-width: 800px) {
    width: 100%;
  }
  overflow-y: visible;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  font-weight: bold;
  scroll-behavior: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  /* IE 10+ */
  div {
    cursor: pointer;
  }
}

.Match-filter-items {
  display: block;

  >* {
    display: inline-block;

    &:focus {
      outline: none !important;
    }

    &:first-child {
      margin-left: 50vw;
    }

    &:last-child {
      margin-right: 50vw;
    }
  }

  >div {
    font-size: 22px;
    padding: 90px 20px 5px 20px;
    margin: 0 10px;
    @media screen and (max-width: 800px) {
      padding: 90px 10px 5px 10px;
      margin: 0 5px;
      font-size: 18px;
    }
    /* prevent hopping on .active changes: */
    border-bottom: 2px solid transparent;

    &:nth-child(1),
    &:nth-last-child(1) {
      opacity: 0.25;
    }

    &:nth-child(2),
    &:nth-last-child(2) {
      opacity: 0.5;
    }

    &:nth-child(3),
    &:nth-last-child(3) {
      opacity: 0.75;
    }

    &.active {
      border-bottom: 2px solid white;
      opacity: 1;
    }
  }

}
